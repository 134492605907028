import { User } from '@/types/user';

class LocalStorageInit extends EventTarget {
	setItem(key: string, value: string) {
		globalThis.localStorage.setItem(key, value);
		this.dispatchEvent(new CustomEvent('set', { detail: { key, value } }));
	}

	setItemFromObjWithUserData<T extends object>(data: T, fields: (keyof T & string)[], user: User) {
		fields.forEach((field) => {
			if (field in data) {
				globalThis.localStorage?.setItem(`${field}-${user?.id}`, String(data[field]));
			}
		});
	}

	setItemWithUserData(key: string, value: string, user: User) {
		globalThis.localStorage?.setItem(`${key}-${user?.id}`, value);
	}

	getItemWithUserData<T>(field: string, user: User, defaultValue: T = null) {
		const value = globalThis.localStorage?.getItem(`${field}-${user?.id}`);
		if (value && value !== 'null') return value;

		return defaultValue;
	}

	getItem(key: string) {
		return globalThis.localStorage?.getItem(key);
	}

	removeItem(key: string) {
		globalThis.localStorage?.removeItem(key);
		this.dispatchEvent(new CustomEvent('remove', { detail: { key } }));
	}

	hasItem(key: string) {
		return (
			globalThis.localStorage?.getItem(key) !== null &&
			globalThis.localStorage?.getItem(key) !== undefined
		);
	}
}

export const LocalStorage = new LocalStorageInit();
