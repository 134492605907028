import { useEffect, useState } from 'react';

export default function useMobile() {
	const [mobile, setMobile] = useState(false);

	useEffect(() => {
		function handler() {
			if (window.screen.width <= 768 && !mobile) setMobile(true);
			if (window.screen.width > 768 && mobile) setMobile(false);
		}

		handler();

		addEventListener('resize', handler);
		return () => {
			removeEventListener('resize', handler);
		};
	}, []);

	return mobile;
}
